/* NB! Everything here is available to the client.

Next.js is geared towards build-time configuration, making runtime variables unreliable.
The next-way would be to build docker image variants for each environment.
*/

const test = {
  appInsightsInstrumentationKey: '92729b46-f7be-4b05-b45c-e6bb05973931',
  authClientId: 'NnREPSZb9XzTgbWf5YwqurrJs0lF6IMq',
  authDomain: 'allier-test.eu.auth0.com',
  authRolesKey: 'https://api.allier.no/roles',
  apiHost: 'https://api.allier-test.raintest.no',
  messageHub: 'https://api.allier-test.raintest.no/messageHub',
  vapidPublicKey: 'BBDBvs6ScMhlsj2YutVuZc5G3oWrmf_HkM-la8b6yqLRsOGm7kFJcAmYIMVbLR-XMO7jHjurwwMsXAjj_hHomsk',
  sanityDataset: 'develop',
  settings: {
    notification: {
      maxRecentNotifications: 10
    }
  },
  isTest: true,
  fileUploadLimitBytes: 100000000
};

const dev = {
  appInsightsInstrumentationKey: 'c255d607-f9fa-4c07-be42-5e15ebe1bfbf',
  authClientId: 'b0UoGrrFYVkJBiq6UTQetYueuyHfp4s8',
  authDomain: 'rainfall.eu.auth0.com',
  authRolesKey: 'https://api.allier.no/roles',
  apiHost: 'https://lekegrind.raintest.no',
  messageHub: 'https://lekegrind.raintest.no/messageHub',
  vapidPublicKey: 'BBDBvs6ScMhlsj2YutVuZc5G3oWrmf_HkM-la8b6yqLRsOGm7kFJcAmYIMVbLR-XMO7jHjurwwMsXAjj_hHomsk',
  sanityDataset: 'develop',
  settings: {
    notification: {
      maxRecentNotifications: 10
    }
  },
  isTest: true,
  fileUploadLimitBytes: 100000000
};

const local = {
  appInsightsInstrumentationKey: null,
  authClientId: 'b0UoGrrFYVkJBiq6UTQetYueuyHfp4s8',
  authDomain: 'rainfall.eu.auth0.com',
  authRolesKey: 'https://api.allier.no/roles',
  apiHost: 'http://localhost:8080',
  messageHub: 'http://localhost:8080/messageHub',
  vapidPublicKey: 'BBDBvs6ScMhlsj2YutVuZc5G3oWrmf_HkM-la8b6yqLRsOGm7kFJcAmYIMVbLR-XMO7jHjurwwMsXAjj_hHomsk',
  sanityDataset: 'develop',
  settings: {
    notification: {
      maxRecentNotifications: 10
    }
  },
  isTest: true,
  fileUploadLimitBytes: 100000000
};

/* eslint-disable quote-props */
const hostConfig = {
  'allier-test.raintest.no': test,
  'allier-dev.raintest.no': dev,
  'localhost': local
};

const hostname = typeof window !== 'undefined' && window.location.hostname;

export default hostConfig[hostname] || {};
